import AL_Logo from './AL_Logo';

function Header () {
  return (
    <header>
      <AL_Logo />
      <h1 className="al_title">Welcome to the AL Vendor Portal.</h1>
    </header>
  )
}
export default Header;