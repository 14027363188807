import React, { useContext, useState } from 'react';
import axios from 'axios';
import logo from './logo.svg';

import Header from './components/Header';
import Login from './components/Login';
import POContainer from './components/POContainer';
import './App.css';

const URL_SIMPLE_API = "https://services.analuisa.com/al_simple_api"
const App = () => {
  const [isLogged, updateLogin] = useState(false);
  const [po, updatePO] = useState({ "poid": null, "vendor": null});

  const logUser = (vendorName, poId) => {
    console.log("LOG USER");
    console.log(vendorName);
    console.log(poId);
    axios.get(`${URL_SIMPLE_API}/production/vendor/indentify?vendor=${vendorName}&docId=${poId}`, {
      "headers": {"Access-Control-Allow-Origin": "*"}
    })
      .then((r) => {
        console.log(r);
        if (r.status === 200) {
          if (r.data && r.data.message === "authorized") {
            updatePO({ "poid": poId, "vendor": vendorName});
            updateLogin(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }



  return (
    <div className="main">
      <Header />
      {(isLogged) ? (
        <POContainer vendorName={po["vendor"]} poId={po["poid"]} url_simple={URL_SIMPLE_API} logoutUser={() => updateLogin(false)} />
        // <POContainer vendorName={"NEVA"} poId={"001130"} url_simple={URL_SIMPLE_API} logoutUser={() => updateLogin(false)} />
      ) : (
        <Login logUser={(vendorName, poId) => logUser(vendorName, poId)} />
      )}
    </div>
  );
}

export default App;
