import React, { useContext, useState } from 'react';
import "./Login.css";

const Login = ({ logUser }) => {
  const [vendorName, updateVendorName] = useState('');
  const [poId, updatePoId] = useState('');

  return (
    <div className="login_container">
      <p className="login_title">Please enter the following information to retrieve your documents:</p>
      <div className="login_input">
        <p className="login_input_title">Vendor Name:</p>
        <input className="login_input_value" type="text" value={vendorName} onChange={(e) => updateVendorName(e.target.value)} />
      </div>
      <div className="login_input">
        <p className="login_input_title">Document ID:<span className="login_input_subtitle">As displayed on PO</span></p>
        <input className="login_input_value" type="text" value={poId} onChange={(e) => updatePoId(e.target.value)} />
      </div>
      {(vendorName != "" && poId != "") ? (
        <button className="login_button" onClick={() => logUser(vendorName, poId)}>ACCESS THE PLATFORM</button>
      ) : (
        <button className="login_button login_button_disabled">ACCESS THE PLATFORM</button>
      )}
    </div>
  );
}

export default Login;
