function AL_Logo () {
  return (
    <div className="al_logo_container">
      <svg className="al_logo" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 323.1 59.3">
        <path d="M200.9,58v-5.9l-0.6,0.8c-2.9,4.1-7.8,6.3-12.7,5.8c-1.4-0.1-2.8-0.3-4.1-0.7c-4.6-1.2-7.9-5.4-8-10.1
          c-0.1-1-0.1-1.9-0.1-2.9c0-8.8,0-17.5,0-26.3v-1.2h14.1V42c0,0.4,0,0.9,0.1,1.3c0.4,4,3.2,5.2,7,4.7c2.5-0.1,4.4-2.2,4.4-4.6
          c0.1-0.5,0.1-1,0.1-1.6c0-7.7,0-15.5,0-23.2v-1.2h14.4V58H200.9z"></path>
        <path d="M86.3,58.1H72.2V33.4c0.1-1.5-0.3-3.1-1.2-4.3c-0.3-0.4-0.7-0.7-1.2-1c-1.9-1-4.1-1.1-6.1-0.3c-2.3,0.9-2.9,2.8-3,5
          c-0.1,3,0,6,0,8.9V58H46.3V17.5h14.4v6c0.3-0.4,0.5-0.6,0.7-0.9c3.1-4.2,8.1-6.4,13.2-5.8c1.3,0.1,2.7,0.4,3.9,0.8
          c4.2,1.2,7.3,4.9,7.6,9.3c0.1,1,0.2,1.9,0.2,2.9c0,9.1,0,18.1,0,27.1C86.3,56.9,86.3,58.1,86.3,58.1z"></path>
        <path d="M239.7,43.8h13.8c0,0.3,0,0.6,0,0.8c0,2.4,0.8,3.5,3.2,4.1c2,0.5,4.1,0.4,6.1-0.2c1.1-0.3,1.9-1.2,2.1-2.4
          c0.5-1.3-0.2-2.8-1.6-3.3c-0.1-0.1-0.3-0.1-0.4-0.1c-1.9-0.5-3.8-0.7-5.8-1.1c-2.6-0.5-5.3-0.8-7.9-1.5c-2.1-0.5-4-1.6-5.5-3.2
          c-2.5-2.7-3.1-6.1-2.8-9.6c0.4-5.2,3.6-8,8.3-9.4c4-1,8.1-1.4,12.3-1.2c2.7,0,5.4,0.4,8,1.1c5.1,1.5,7.8,5.1,7.7,10.4v2h-12.4
          c0-0.1-0.1-0.2-0.1-0.3c-0.1-2.1-0.7-3-2.8-3.4c-2.2-0.5-4.4-0.4-6.5,0.3c-1.8,0.6-2.5,2.3-1.6,3.7c0.5,0.7,1.1,1.2,1.9,1.4
          c1.8,0.5,3.7,0.8,5.6,1.1c3.5,0.5,7,0.9,10.5,1.6c1.7,0.4,3.3,1.2,4.7,2.3c1.7,1.3,2.7,3.3,2.8,5.4c0.5,3,0,6.1-1.3,8.9
          c-1.6,3.2-4.4,5-7.8,6.1c-4.7,1.4-9.5,1.5-14.3,1.3c-3,0-6-0.5-8.9-1.4c-3.9-1.2-6.8-4.6-7.1-8.7C239.8,47,239.8,45.5,239.7,43.8z"></path>
        <path d="M169.7,58.1h-14.4V0h14.4V58.1z"></path>
        <path d="M235.3,17.5V58h-14.4V17.5H235.3z"></path>
        <path d="M221,0h14.3v13H221V0z"></path>
        <path d="M40.7,32.8c0-0.6-0.1-3-0.2-3.5c-0.2-2.7-1.2-5.2-2.9-7.3l0,0c-1.8-2-4.1-3.4-6.7-4c-2.8-0.8-5.7-1.2-8.6-1.3h-0.1
          c-2.9-0.1-5.7,0.2-8.5,0.7c-1.7,0.3-3.4,0.9-4.9,1.6c-1.6,0.8-3,1.8-4.2,3.1c-1,1.1-1.8,2.3-2.3,3.7c-0.5,1.4-0.8,3-0.8,4.5
          c0,0,0,0,0,0.1c0,0.4,0,0.9,0,1.4C1.6,33,1.7,34.2,2,35.4c3.1-1.6,6.4-2.8,9.9-3.5c0.8-0.1,1.7-0.3,2.6-0.4h0.2c0-0.3,0-0.4,0-0.7
          l0,0c0-0.9,0.2-1.8,0.7-2.5c0.2-0.3,0.5-0.6,0.9-0.9s0.8-0.5,1.3-0.6c1.1-0.3,2.2-0.5,3.3-0.5c0.5,0,1.1,0.1,1.6,0.2
          c0.6,0.1,1.1,0.3,1.6,0.5c0.2,0.1,0.4,0.2,0.6,0.3c0.6,0.4,1.1,1,1.2,1.7c0.2,1.1,0.2,2.2,0.3,3.3c0,0.5-0.2,0.7-0.7,0.8
          c-3.1,0.3-6.3,0.7-9.4,1.1c-3.5,0.3-6.9,1.2-10,2.8c-1.2,0.6-2.2,1.5-3.1,2.5c-0.4,0.5-0.8,1-1.1,1.6S1.3,42.4,1.1,43
          c-0.3,0.9-0.4,1.8-0.5,2.7s-0.1,1.8,0,2.8c0.1,1.1,0.2,2.1,0.6,3.1C2,53.9,3.6,55.9,5.8,57c0.5,0.3,1,0.5,1.5,0.7
          c0.9,0.4,1.8,0.6,2.7,0.8s1.8,0.3,2.8,0.4c1.1,0,2.1,0,3.2-0.1c0.8-0.1,1.6-0.2,2.4-0.3c0.5-0.1,1.1-0.3,1.6-0.5
          c2.4-0.8,4.4-2.2,5.9-4.2c0.1-0.1,0.2-0.2,0.4-0.5v4.9h14.4V57C40.7,48.9,40.7,40.8,40.7,32.8z M25.2,45.8c-1.6,2.8-4.9,3.8-8.4,3.1
          c-0.8-0.1-1.6-0.6-2.1-1.2C14.2,47,14,46.2,14,45.4c0-0.4,0.1-0.8,0.2-1.2c0.5-1.2,1.5-2.1,2.8-2.3c0.6-0.1,1.3-0.2,2-0.3l2-0.3
          l5.2-0.8C26.4,42.3,26.1,44.2,25.2,45.8z"></path>
        <path d="M131.7,32.8c0-0.6-0.1-3-0.2-3.5c-0.2-2.7-1.2-5.2-2.9-7.3l0,0c-1.8-2-4.1-3.4-6.7-4c-2.8-0.8-5.7-1.2-8.6-1.3h-0.1
          c-2.9-0.1-5.7,0.2-8.5,0.7c-1.7,0.3-3.4,0.9-4.9,1.6c-1.6,0.8-3,1.8-4.2,3.1c-1,1.1-1.8,2.3-2.3,3.7c-0.5,1.4-0.8,3-0.8,4.5
          c0,0,0,0,0,0.1c0,0.4,0,0.9,0,1.4c0.1,1.2,0.2,2.4,0.5,3.6c3.1-1.6,6.4-2.8,9.9-3.5c0.8-0.1,1.7-0.3,2.6-0.4h0.2c0-0.3,0-0.4,0-0.7
          l0,0c0-0.9,0.2-1.8,0.7-2.5c0.2-0.3,0.6-0.6,0.9-0.9c0.4-0.3,0.8-0.5,1.3-0.6c1.1-0.3,2.2-0.5,3.3-0.5c0.6,0,1.1,0.1,1.6,0.2
          c0.6,0.1,1.1,0.3,1.6,0.5c0.2,0.1,0.4,0.2,0.6,0.3c0.6,0.4,1,1,1.1,1.7c0.2,1.1,0.2,2.2,0.3,3.3c0,0.5-0.2,0.7-0.7,0.8
          c-3.1,0.3-6.3,0.7-9.4,1.1c-3.5,0.3-6.9,1.2-10,2.8c-1.2,0.6-2.2,1.5-3.1,2.5c-0.4,0.5-0.7,1-1,1.6s-0.6,1.3-0.8,1.9
          c-0.3,0.9-0.4,1.8-0.5,2.7s-0.1,1.8,0,2.8c0.1,1.1,0.2,2.1,0.6,3.1c0.8,2.3,2.4,4.3,4.6,5.4c0.5,0.3,1,0.5,1.5,0.7
          c0.9,0.4,1.8,0.6,2.7,0.8c0.9,0.2,1.8,0.3,2.8,0.4c1.1,0,2.1,0,3.2-0.1c0.8-0.1,1.6-0.2,2.4-0.3c0.5-0.1,1.1-0.3,1.6-0.5
          c2.4-0.8,4.4-2.2,5.9-4.2c0.1-0.1,0.2-0.2,0.4-0.5V58h14.4v-1C131.7,48.9,131.7,40.8,131.7,32.8z M116.2,45.8
          c-1.6,2.8-4.9,3.8-8.4,3.1c-0.8-0.1-1.6-0.6-2.1-1.2c-0.5-0.7-0.7-1.5-0.7-2.3c0-0.4,0.1-0.8,0.2-1.2c0.5-1.2,1.5-2.1,2.8-2.3
          c0.7-0.1,1.3-0.2,2-0.3l2-0.3l5.2-0.8C117.4,42.3,117.1,44.2,116.2,45.8z"></path>
        <path d="M323.1,32.8c0-0.6-0.1-3-0.2-3.5c-0.2-2.7-1.2-5.2-2.9-7.3l0,0c-1.8-2-4.1-3.4-6.7-4c-2.8-0.8-5.7-1.2-8.6-1.3h-0.1
          c-2.9-0.1-5.7,0.2-8.5,0.7c-1.7,0.3-3.4,0.9-4.9,1.6c-1.6,0.8-3,1.8-4.2,3.1c-1,1.1-1.8,2.3-2.3,3.7c-0.5,1.4-0.8,3-0.8,4.5v0.1
          c0,0.4,0,0.9,0,1.4c0.1,1.2,0.2,2.4,0.5,3.6c3.1-1.6,6.4-2.8,9.9-3.5c0.8-0.1,1.7-0.3,2.6-0.4h0.2c0-0.3,0-0.4,0-0.7l0,0
          c0-0.9,0.2-1.8,0.7-2.5c0.2-0.3,0.6-0.6,0.9-0.9c0.4-0.3,0.8-0.5,1.3-0.6c1.1-0.3,2.2-0.5,3.3-0.5c0.6,0,1.1,0.1,1.6,0.2
          s1.1,0.3,1.6,0.4c0.2,0.1,0.4,0.2,0.6,0.3c0.6,0.4,1,1,1.1,1.7c0.2,1.1,0.2,2.2,0.3,3.3c0,0.5-0.2,0.7-0.7,0.8
          c-3.1,0.3-6.3,0.7-9.4,1.1c-3.5,0.3-6.9,1.2-10,2.8c-1.2,0.6-2.3,1.5-3.1,2.5c-0.4,0.5-0.8,1-1.1,1.6c-0.3,0.6-0.6,1.3-0.8,1.9
          c-0.3,0.9-0.4,1.8-0.5,2.7s-0.1,1.8,0,2.8c0.1,1.1,0.3,2.1,0.6,3.1c0.8,2.3,2.4,4.3,4.6,5.4c0.5,0.3,1,0.5,1.5,0.7
          c0.9,0.4,1.8,0.6,2.7,0.8c0.9,0.2,1.8,0.3,2.8,0.4c1.1,0,2.1,0,3.2-0.1c0.8-0.1,1.6-0.2,2.4-0.3c0.5-0.1,1-0.3,1.6-0.5
          c2.4-0.8,4.4-2.2,5.9-4.2c0.1-0.2,0.3-0.3,0.5-0.5V58h14.5v-1C323.2,48.9,323.1,40.8,323.1,32.8z M307.6,45.8
          c-1.6,2.8-4.9,3.8-8.4,3.1c-0.9-0.1-1.6-0.6-2.2-1.2c-0.5-0.7-0.7-1.5-0.7-2.3c0-0.4,0.1-0.8,0.2-1.2c0.5-1.2,1.5-2.1,2.8-2.3
          c0.7-0.1,1.3-0.2,2-0.3l2-0.3l5.3-0.8C308.9,42.3,308.5,44.2,307.6,45.8z"></path>
      </svg>
    </div>
  )
}
export default AL_Logo;