import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import JsBarcode from 'jsbarcode';
import FileDownload from 'js-file-download';
import ALLoading from "./ALLoading";

import './POContainer.css';

let VENDORS_DATA = [{
  name: "ANA LUISA STARTING INVENTORY",
  vendorId: 23459,
  referenceCode: "ZY+test",
  madeIn: "ZY+test",
}, {
  name: "ATELIERS TAMALET CORP",
  vendorId: 23115,
  referenceCode: "AE",
  madeIn: "China",
}, {
  name: "Chete LTD",
  vendorId: 23116,
  referenceCode: "AF",
  madeIn: "China",
}, {
  name: "Ennovie",
  vendorId: 23129,
  referenceCode: "BE",
  madeIn: "Thailand",
}, {
  name: "ENO DONG GUAN JUAN JUAN JEWELRY CO LTD",
  vendorId: 23121,
  referenceCode: "AG",
  madeIn: "China",
}, {
  name: "Firmstock Limited.",
  vendorId: 23127,
  referenceCode: "BC",
  madeIn: "China",
}, {
  name: "HMR QINGDAO HANMIRAI JEWELRY CO LTD",
  vendorId: 23123,
  referenceCode: "AI",
  madeIn: "China",
}, {
  name: "Jupiter Mills",
  vendorId: 23128,
  referenceCode: "BD",
  madeIn: "United States",
}, {
  name: "L&P (QINGDAO STYLE ARTS & CRAFT CO LTD)",
  vendorId: 23130,
  referenceCode: "BF",
  madeIn: "China",
}, {
  name: "Leo Components AG",
  vendorId: 23124,
  referenceCode: "AJ",
  madeIn: "Austria",
}, {
  name: "Mercery Jewelry Company Limited",
  vendorId: 23114,
  referenceCode: "AD",
  madeIn: "China",
}, {
  name: "MISHELL KOREA",
  vendorId: 23112,
  referenceCode: "AB",
  madeIn: "China",
}, {
  name: "MJJ Brilliant",
  vendorId: 23122,
  referenceCode: "AH",
  madeIn: "Mexico",
}, {
  name: "NEVA",
  vendorId: 23111,
  referenceCode: "AA",
  madeIn: "China",
}, {
  name: "QINGDAO PURGO JEWELRY Co., Ltd.",
  vendorId: 23113,
  referenceCode: "AC",
  madeIn: "China",
}, {
  name: "SHINING GARMENT ACCESSORIES LTD",
  vendorId: 23125,
  referenceCode: "BA",
  madeIn: "China",
}, {
  name: "TEST",
  vendorId: 23327,
  referenceCode: "ZZ+test",
  madeIn: "ZZ+test",
}, {
  name: "Wuxi Magic Textile Co., Ltd.",
  vendorId: 23126,
  referenceCode: "BB",
  madeIn: "China",
}, {
  name: "B&C",
  vendorId: 23978,
  referenceCode: "BG",
  madeIn: "USA",
}, {
  name: "BETA",
  vendorId: 24484,
  referenceCode: "BJ",
  madeIn: "China",
}, {
  name: "EVERFIT",
  vendorId: 24485,
  referenceCode: "BH",
  madeIn: "China",
}, {
  name: "PRINTPACKNET",
  vendorId: 24864,
  referenceCode: "ZX",
  madeIn: "USA",
}, {
  name: "SENYUTE",
  vendorId: 24487,
  referenceCode: "CA",
  madeIn: "China",
}, {
  name: "STANDFORD",
  vendorId: 24486,
  referenceCode: "BI",
  madeIn: "China",
}, {
  name: "TOWN TALK",
  vendorId: 25109,
  referenceCode: "CB",
  madeIn: "United Kingdom",
}, {
  name: "CATTIVO",
  vendorId: 26521,
  referenceCode: "CD",
  madeIn: "China",
}, {
  name: "EASTERN POSE ACCESSORIES CORP.,LTD.",
  vendorId: 27502,
  referenceCode: "CO",
  madeIn: "China",
}, {
  name: "BLU ORO",
  vendorId: 29936,
  referenceCode: "CD",
  madeIn: "Italy",
}, {
  name: "PARCOME",
  vendorId: 29971,
  referenceCode: "CE",
  madeIn: "China",
}, {
  name: "Midas",
  vendorId: 30056,
  referenceCode: "CF",
  madeIn: "Turkey",
}, {
  name: "UNOAERRE INDUSTRIES S.p.A.",
  vendorId: 30088,
  referenceCode: "CG",
  madeIn: "Italy",
}, {
  name: "AL CYCLECOUNT",
  vendorId: 26637,
  referenceCode: "AY",
  madeIn: "",
}, {
  name: "AL BROOKLYN",
  vendorId: 30256,
  referenceCode: "AX",
  madeIn: "",
}];


const POContainer = ({ vendorName, poId, url_simple, logoutUser }) => {
  const [po, updatePO] = useState(null);
  const [vendor, updateVendor] = useState(null);
  const [downloadedPDFs, updatePDFList] = useState([]);
  const [inProgressDownload, updateInProgressDownload] = useState([]);

  useEffect(() => {
    // GET PO INFORMATIONS
    axios.get(`${url_simple}/production/po/${poId}`, {
      "headers": {"Access-Control-Allow-Origin": "*"}
    }).then((r) => {
      if (r.status === 200) {
        updatePO(r.data.data[0]);
      }
    });
  }, [])


  useEffect(() => {
    if (po) {
      // GET VENDOR INFORMATION
      axios.get(`${url_simple}/production/vendor/${po["vendorId"]}`, {
        "headers": {"Access-Control-Allow-Origin": "*"}
      }).then((r) => {
        if (r.status === 200) {
          updateVendor(r.data.data);
        }
      });
    }
  }, [po]);

  useEffect(() => {
    if (vendor) {
      console.log(vendor);
      console.log(vendor["name"]);
      console.log(vendorName);
      if (vendor["name"] !== vendorName) {
        // LOG OUT ! BAD VENDOR INFO
        logoutUser();
      }
    }
  }, [vendor])


  const get_reference_code = () => {
    let today = moment(po['dateCreated']);
    // Get last digit of current year
    let _year = today.year().toString().slice(3,4);
    let _month = (today.month() + 1) * 2; // Accepts numbers from 0 to 11
    let _day = today.date() * 2;
    // CONCATENATE(RIGHT(YEAR(TODAY()), LEN(YEAR(TODAY()))-3), MONTH(TODAY())*2, DAY(TODAY())*2)
    let digits = _year + _month.toString() + _day.toString();
    let refCode = VENDORS_DATA.find((e) => e["vendorId"] === po["vendorId"])["referenceCode"];
    return refCode + digits;
  }
  const get_made_in = () => {
    let madeIn = VENDORS_DATA.find((e) => e["vendorId"] === po["vendorId"])["madeIn"];
    return madeIn;
  }

  const get_barcode_data = (valueCode) => {
    var { createCanvas } = require("canvas");
    var canvas = createCanvas();
    JsBarcode(canvas, valueCode, {
      width: 4,
      height: 100,
      margin: 0,
      displayValue: false
    });
    return canvas.toDataURL();
  }


  const downloadProductPDFs = async (masterSku, rowId) => {
    // Add loading
    updateInProgressDownload(prevArray => [...prevArray, rowId]);

    console.log(masterSku);
    axios.get(`${url_simple}/production/product/${masterSku}`, {
      "headers": {"Access-Control-Allow-Origin": "*"}
    }).then(async (r) => {
      if (r.status === 200) {
        // raw product data
        let productData = r.data.data;
        // ref code

        console.log(productData, "productData");

        // Build product obj to override html data
        let _final_data = {};
        let isRing = false;
        if ((productData["categories"].findIndex(e => e == "Class- Rings") > -1) || (productData["categories"].findIndex(e => e == "CLASS-RINGS") > -1)) {
          isRing = true;
        }
        _final_data["isRing"] = isRing;

        let _color = productData["attributeGroups"].find(e => e["attributeGroupId"] == 5634);
        let _size = productData["attributeGroups"].find(e => e["attributeGroupId"] == 5635);
        if (_color) {
          _final_data["color"] = _color["attributes"][0]["name"];
        }
        if (_size) {
          _final_data["size"] = _size["attributes"][0]["name"];
        }

        _final_data["barcode"] = productData["masterSku"];
        _final_data["name"] = productData["description"];
        _final_data["sku"] = productData["masterSku"].slice(0,6); // not the real sku => just the 6 digit one


        _final_data["referenceCode"] = get_reference_code();
        _final_data["madeIn"] = get_made_in();


        _final_data["barcodeData"] = get_barcode_data(productData["masterSku"]);

        // General info
        _final_data["customPurchaseOrderNumber"] = po["customPurchaseOrderNumber"];
        _final_data["vendorName"] = vendorName;
        _final_data["poId"] = poId;


        console.log(_final_data, "=>>>>> FINAL DATA");

        await axios({
          method: 'post',
          url: `${url_simple}/production/product/pdfs`,
          data: _final_data,
          responseType: 'blob'
        }).then((r) => {
          console.log("RESULT ZIP PDF");
          console.log(r);
          let fileName = `analuisa_pdfs_${_final_data["barcode"]}_${poId}.zip`;
          FileDownload(r.data, fileName);
          updatePDFList(prevArray => [...prevArray, rowId]);
        });
      }
    });

  }

  return (
    <div className="po_result">
      <p className="po_result_title">You must download all stickers and barcodes for this order</p>
      {(po && "purchaseOrderItems" in po && po["purchaseOrderItems"].length > 0) ? (
        <div className="po_result_items">
          <div className="line_item line_item_header" key={`line_item_key`}>
            <p className="line_item_sku">SKU</p>
            <p className="line_item_dl_h">Link</p>
          </div>
          {po["purchaseOrderItems"].map((p, i) => {
            return (
              <div className="line_item" key={`line_item_${i}`}>
                <p className="line_item_sku">{p["vendorProductMasterSku"]}</p>
                {(downloadedPDFs.includes(`line_item_${i}`)) ? (
                  <div className="line_item_dl_done">✅</div>
                ) : (
                  inProgressDownload.includes(`line_item_${i}`) ? (
                    <ALLoading className="loading_pdfs" />
                  ) : (
                    <button className="line_item_dl" onClick={() => downloadProductPDFs(p["vendorProductMasterSku"], `line_item_${i}`)}>
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAABeElEQVR4nO2ZMU7DMBhGnytOAis36ModGBCsPQJrK1bEBAKprEXiPoz0BEwIZsIQR02jJJCo6Zcm35MsWY3TPH/541YxGGOMMcaYcRKE154AF7G/An6ELhKugCS2S5XERHVh4DjXP1FJKAPoBQ5ALaDGAagF1DgAtYAaB6AWUOMA1AJqHIBaQI0DUAuo6TqAG+AbuKXZ26cA3MVzFx147Y0vNm99ntgOYZ47Ns99HuLY7Nhnl4JdV8Ay158Bj9RXQohjZhXfcXAE4J7N3UyAZ9LgixUQgIfC2CUDWKeqQliwHcAgJ59RfK4T4KOiX7ZeDIKySihrg7rzRf4KYdCTz6gKYRSTzyiGIJu8cqEJwHnsv5IGYfZNkwro+25u53692M2toZVfk4WnF7u5NbTyG83PThUOQC2gxgGoBdQ4ALWAmqOW502B612K7IBpm5PaBnAW28HT5BFYd2axe97/O7BJBaxI/2efNtbZL2/Ai1rCGGOMMcb0nV/wQ2Ny2wsxgAAAAABJRU5ErkJggg=="/>  
                    </button>
                  )
                )}
              </div>
            )
          })}
        </div>
      ) : (null)}
    </div>
  );
}

export default POContainer;