import PropTypes from "prop-types";
import "./ALLoading.css";

function ALLoading({ className, fill = "black" }) {
  return (
    <svg
      className={`${className || ""} al_loading_spinner`}
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M65 32.5C65 39.5379 62.7154 46.3856 58.4898 52.0137C54.2641 57.6417 48.3256 61.746 41.5672 63.7095C34.8088 65.673 27.5956 65.3896 21.0121 62.9019C14.4285 60.4142 8.83042 55.8566 5.05934 49.9144C1.28826 43.9721 -0.451999 36.9663 0.100187 29.9501C0.652373 22.9339 3.46716 16.2865 8.12139 11.0074C12.7756 5.72817 19.0178 2.10245 25.9094 0.675258C32.8011 -0.751933 39.9698 0.0965413 46.3378 3.09312L44.954 6.03381C39.2228 3.33689 32.771 2.57326 26.5685 3.85773C20.366 5.1422 14.7481 8.40536 10.5593 13.1566C6.37045 17.9079 3.83714 23.8905 3.34017 30.2051C2.8432 36.5196 4.40943 42.8249 7.80341 48.1729C11.1974 53.521 16.2357 57.6228 22.1609 59.8617C28.086 62.1007 34.5779 62.3557 40.6605 60.5886C46.7431 58.8214 52.0877 55.1276 55.8908 50.0623C59.6939 44.997 61.75 38.8341 61.75 32.5H65Z"
        fill={fill}
      />
    </svg>
  );
}

ALLoading.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default ALLoading;